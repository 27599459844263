var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Kupon',
        href: {
          name: 'admin.coupons'
        }
      }, {
        label: 'Tambah Kupon',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px'],
      "min-height": ['unset', '74vh'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": "30px",
      "justify": "space-between",
      "align-items": "center",
      "max-width": "540px",
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" Tambah Kupon ")])], 1), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "width": "100%",
      "max-width": "540px"
    }
  }, [_c('BaseInputText', {
    attrs: {
      "label": "Nama Kupon",
      "placeholder": "Masukkan Nama Kupon",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.name),
      "invalid-text": _vm.parseErrors('Nama kupon', _vm.$v.name)
    },
    on: {
      "blur": _vm.$v.name.$touch
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Kode",
      "placeholder": "Masukkan Kode",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.code),
      "invalid-text": _vm.parseErrors('Kode', _vm.$v.code)
    },
    on: {
      "blur": _vm.$v.code.$touch
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Kuota",
      "placeholder": "Masukkan Kuota",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.quota),
      "invalid-text": _vm.parseErrors('Kuota', _vm.$v.quota)
    },
    on: {
      "blur": _vm.$v.quota.$touch
    },
    model: {
      value: _vm.quota,
      callback: function callback($$v) {
        _vm.quota = $$v;
      },
      expression: "quota"
    }
  }), _c('c-flex', {
    attrs: {
      "gap": "16px"
    }
  }, [_c('BaseInputDate', {
    attrs: {
      "type": "date",
      "label": "Start Date",
      "placeholder": "Pilih Start Date",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.startAt),
      "invalid-text": _vm.parseErrors('Start date', _vm.$v.startAt)
    },
    on: {
      "blur": _vm.$v.startAt.$touch
    },
    model: {
      value: _vm.startAt,
      callback: function callback($$v) {
        _vm.startAt = $$v;
      },
      expression: "startAt"
    }
  }), _c('BaseInputDate', {
    attrs: {
      "type": "date",
      "label": "End Date",
      "placeholder": "Pilih End Date",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.endAt),
      "invalid-text": _vm.parseErrors('End date', _vm.$v.endAt)
    },
    on: {
      "blur": _vm.$v.endAt.$touch
    },
    model: {
      value: _vm.endAt,
      callback: function callback($$v) {
        _vm.endAt = $$v;
      },
      expression: "endAt"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "gap": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "max-width": "185px",
      "width": "100%"
    }
  }, [_c('BaseInputSelect', {
    attrs: {
      "label": "Tipe",
      "options": [{
        value: 'fix',
        label: 'Fix'
      }, {
        value: 'percent',
        label: '%'
      }],
      "placeholder": "Pilih Tipe Kupon",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.type),
      "invalid-text": _vm.parseErrors('Tipe kupon', _vm.$v.type),
      "full-width": ""
    },
    on: {
      "blur": _vm.$v.type.$touch,
      "trigger-change": function triggerChange($event) {
        _vm.totalDiscount = '';
      }
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1), _c('BaseInputText', {
    attrs: {
      "label": "Jumlah Potongan",
      "placeholder": "Masukkan ".concat(_vm.type === 'fix' ? 'Jumlah' : '%', " Potongan"),
      "full-width": "",
      "input-left-addon": _vm.type === 'fix' ? 'Rp' : '%',
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.totalDiscount),
      "invalid-text": _vm.parseErrors('Jumlah potongan', _vm.$v.totalDiscount)
    },
    on: {
      "blur": _vm.$v.totalDiscount.$touch
    },
    model: {
      value: _vm.totalDiscount,
      callback: function callback($$v) {
        _vm.totalDiscount = $$v;
      },
      expression: "totalDiscount"
    }
  })], 1), _c('BaseInputCheckbox2', {
    attrs: {
      "is-required": "",
      "label": "Gunakan untuk program",
      "options": _vm.listProducts.map(function (product) {
        return {
          label: product.name,
          value: product.id
        };
      }),
      "is-invalid": _vm.isInvalidField(_vm.$v.productIds),
      "invalid-text": _vm.parseErrors('Program', _vm.$v.productIds)
    },
    on: {
      "blur": _vm.$v.productIds.$touch
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var isSelected = _ref.isSelected,
            option = _ref.option;
        return [isSelected ? _c('c-box', {
          attrs: {
            "padding-left": "28px",
            "margin-bottom": "100px"
          }
        }, [_c('BaseInputMultiTag', {
          attrs: {
            "is-loading": _vm.isLoadingProducts[option.value],
            "options": _vm.dataProducts[option.value] || [],
            "without-invalid-text": ""
          },
          model: {
            value: _vm.selectedProducts[option.value],
            callback: function callback($$v) {
              _vm.$set(_vm.selectedProducts, option.value, $$v);
            },
            expression: "selectedProducts[option.value]"
          }
        })], 1) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.productIds,
      callback: function callback($$v) {
        _vm.productIds = $$v;
      },
      expression: "productIds"
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "28px",
      "justify-content": "space-between",
      "gap": "16px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "border-radius": "1000px",
      "size": "large",
      "width": "100%",
      "variant": "outlined"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" Batal ")]), _c('BaseButton', {
    attrs: {
      "border-radius": "1000px",
      "size": "large",
      "width": "100%",
      "disabled": _vm.$v.$invalid
    },
    on: {
      "click": function click($event) {
        _vm.isOpenModal = true;
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModal,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah anda yakin "), _c('br'), _vm._v(" data kupon yang anda masukkan sudah benar? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoadingModal
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModal = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingModal
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <c-box flex-grow="1">
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        { label: 'Manajemen Kupon', href: { name:'admin.coupons' } },
        { label: 'Tambah Kupon', isCurrent: true },
      ]"
    />
    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <c-flex
        margin-bottom="30px"
        justify="space-between"
        align-items="center"
        max-width="540px"
        width="100%"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
        >
          Tambah Kupon
        </BaseText>
      </c-flex>

      <form
        v-chakra
        width="100%"
        max-width="540px"
      >
        <BaseInputText
          v-model="name"
          label="Nama Kupon"
          placeholder="Masukkan Nama Kupon"
          full-width
          is-required
          :is-invalid="isInvalidField($v.name)"
          :invalid-text="parseErrors('Nama kupon', $v.name)"
          @blur="$v.name.$touch"
        />
        <BaseInputText
          v-model="code"
          label="Kode"
          placeholder="Masukkan Kode"
          full-width
          is-required
          :is-invalid="isInvalidField($v.code)"
          :invalid-text="parseErrors('Kode', $v.code)"
          @blur="$v.code.$touch"
        />
        <BaseInputText
          v-model="quota"
          label="Kuota"
          placeholder="Masukkan Kuota"
          full-width
          is-required
          :is-invalid="isInvalidField($v.quota)"
          :invalid-text="parseErrors('Kuota', $v.quota)"
          @blur="$v.quota.$touch"
        />
        <c-flex
          gap="16px"
        >
          <BaseInputDate
            v-model="startAt"
            type="date"
            label="Start Date"
            placeholder="Pilih Start Date"
            full-width
            is-required
            :is-invalid="isInvalidField($v.startAt)"
            :invalid-text="parseErrors('Start date', $v.startAt)"
            @blur="$v.startAt.$touch"
          />
          <BaseInputDate
            v-model="endAt"
            type="date"
            label="End Date"
            placeholder="Pilih End Date"
            full-width
            is-required
            :is-invalid="isInvalidField($v.endAt)"
            :invalid-text="parseErrors('End date', $v.endAt)"
            @blur="$v.endAt.$touch"
          />
        </c-flex>

        <c-flex
          gap="16px"
        >
          <c-box
            max-width="185px"
            width="100%"
          >
            <BaseInputSelect
              v-model="type"
              label="Tipe"
              :options="[{
                value: 'fix',
                label: 'Fix',
              }, {
                value: 'percent',
                label: '%',
              }]"
              placeholder="Pilih Tipe Kupon"
              is-required
              :is-invalid="isInvalidField($v.type)"
              :invalid-text="parseErrors('Tipe kupon', $v.type)"
              full-width
              @blur="$v.type.$touch"
              @trigger-change="totalDiscount = ''"
            />
          </c-box>
          <BaseInputText
            v-model="totalDiscount"
            label="Jumlah Potongan"
            :placeholder="`Masukkan ${type === 'fix' ? 'Jumlah' : '%'} Potongan`"
            full-width
            :input-left-addon="type === 'fix' ? 'Rp' : '%'"
            is-required
            :is-invalid="isInvalidField($v.totalDiscount)"
            :invalid-text="parseErrors('Jumlah potongan', $v.totalDiscount)"
            @blur="$v.totalDiscount.$touch"
          />
        </c-flex>

        <BaseInputCheckbox2
          v-model="productIds"
          is-required
          label="Gunakan untuk program"
          :options="listProducts.map((product) => ({
            label: product.name,
            value: product.id,
          }))"
          :is-invalid="isInvalidField($v.productIds)"
          :invalid-text="parseErrors('Program', $v.productIds)"
          @blur="$v.productIds.$touch"
        >
          <template #default="{ isSelected, option }">
            <c-box
              v-if="isSelected"
              padding-left="28px"
              margin-bottom="100px"
            >
              <BaseInputMultiTag
                v-model="selectedProducts[option.value]"
                :is-loading="isLoadingProducts[option.value]"
                :options="dataProducts[option.value] || []"
                without-invalid-text
              />
            </c-box>
          </template>
        </BaseInputCheckbox2>

        <c-flex
          mt="28px"
          justify-content="space-between"
          gap="16px"
        >
          <BaseButton
            border-radius="1000px"
            size="large"
            width="100%"
            variant="outlined"
            @click="$router.back()"
          >
            Batal
          </BaseButton>
          <BaseButton
            border-radius="1000px"
            size="large"
            width="100%"
            :disabled="$v.$invalid"
            @click="isOpenModal = true"
          >
            Simpan
          </BaseButton>
        </c-flex>
      </form>

      <BaseModal
        :is-open="isOpenModal"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #body>
          <c-flex
            justify-content="center"
            padding="30px 24px"
            text-align="center"
          >
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="primary.400"
            >
              Apakah anda yakin <br>
              data kupon yang anda masukkan sudah benar?
            </BaseText>
          </c-flex>
        </template>
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              variant="outlined"
              rounded="1000px"
              width="100%"
              :disabled="isLoadingModal"
              @click="isOpenModal = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              rounded="1000px"
              width="100%"
              :is-loading="isLoadingModal"
              @click="onSubmit"
            >
              Oke
            </BaseButton>
          </c-flex>
        </template>
      </BaseModal>
    </c-box>
  </c-box>
</template>

<script>
import { mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import BaseInputText from '@/components/elements/base-input-text.vue'
import BaseInputDate from '@/components/elements/base-input-date.vue'
import BaseInputSelect from '@/components/elements/base-input-select.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'
import { required, minValue, integer } from 'vuelidate/lib/validators'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import gteDate from '@/utils/vuelidate-custom-validator/gte-date'
import lteDate from '@/utils/vuelidate-custom-validator/lte-date'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseInputMultiTag from '@/components/elements/base-input-multi-tag.vue'
import BaseInputCheckbox2 from '@/components/elements/base-input-checkbox-2.vue'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import _ from 'lodash'

export default {
  name: 'ManagementCouponsForm',
  components: {
    BaseInputCheckbox2, BaseInputMultiTag,
    BaseModal,
    BaseText,
    BreadcrumbPath,
    BaseButton,
    BaseInputSelect,
    BaseInputDate,
    BaseInputText,
  },
  mixins: [
    generalMixin,
  ],
  data() {
    return {
      isLoadingModal: false,
      isOpenModal: false,
      name: null,
      code: null,
      type: 'fix',
      totalDiscount: null,
      startAt: null,
      endAt: null,
      quota: null,
      productIds: [],
      listProducts: [],
      selectedProducts: {},
      isLoadingProducts: {},
      dataProducts: {},
    }
  },
  computed: {
    getFilteredSelectedProducts() {
      let result = []
      this.productIds?.forEach((productId) => {
        if (this.selectedProducts[productId]) {
          this.selectedProducts[productId]?.forEach((selectedProduct) => {
            result.push(selectedProduct?.id)
          })
        }
      })
      return result
    },
    getProductIds() {
      return this.productIds
    },
    couponId() {
      return this.$route.params.id
    },
  },
  watch: {
    getProductIds: {
      immediate: true,
      handler(val) {
        val.forEach((id) => {
          if (!this.dataProducts[id]) {
            this.$set(this.isLoadingProducts, id, true)
          }
        })
      },
    },
    productIds: {
      handler: _.debounce(function(val) {
        val.forEach((id) => {
          if (!this.dataProducts[id]) {
            this.detailProduct(id)
              .then((res) => {
                this.$set(this.dataProducts, id, res?.productServices)
              }).catch((e) => {
                this.$toast({
                  status: 'error',
                  title: 'Error',
                  description: parseErrorCatch(e),
                  duration: 3000,
                })
              })
              .finally(() => {
                this.isLoadingProducts[id] = false
              })
          }
        })
      }, 1200),
      deep: true,
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    parseErrors,
    isInvalidField,
    ...mapActions({
      createCoupons: 'admCoupons/createCouponsAdmin',
      loadProducts: 'clients/getProducts',
      detailProduct: 'clients/getProductById',
    }),
    onSubmit() {
      this.isLoadingModal = true
      let params = {
        name: this.name,
        code: this.code,
        type: this.type,
        totalDiscount: this.totalDiscount,
        startAt: this.startAt,
        endAt: this.endAt,
        quota: this.quota,
        productIds: this.productIds,
        productServiceIds: this.getFilteredSelectedProducts,

      }
      this.createCoupons(params)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Create coupon is successfully',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'admin.coupons',
            query: { type: this.type },
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: parseErrorCatch(err),
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }).finally(() => {
          this.isLoadingModal = false
          this.isOpenModal = false
        })
    },
    async init() {
      const [products] = await Promise.all([
        this.loadProducts(),
      ])
      this.listProducts = products?.data
    },
  },
  validations() {
    return {
      name: { required },
      code: { required },
      quota: { required, integer, minValue: minValue(1) },
      startAt: { required, lteDate: lteDate('endAt', 'End date') },
      endAt: { required, gteDate: gteDate('startAt', 'Start date') },
      type: { required },
      totalDiscount: {
        required,
        ...(this.type === 'fix' ? { integer } : {}),
        minValue: minValue(1),
      },
      productIds: { required },
    }
  },
}
</script>
